import React from 'react'
import Contact1 from '../components/Contact/Contact1'
import Whatsapp from '../components/Uconstruction/Whatsapp'

const ContactR = () => {
  return (
<>
<Contact1/>
<Whatsapp/>

</>
  )
}

export default ContactR